<template>
  <!-- 机票列表 原单 -->
  <van-pull-refresh
    v-model="isLoading"
    @refresh="onRefresh"
    :disabled="!canRefresh||canRefreshChild"
  >
    <!-- 票信息 -->
    <PaymentUI :order="order" :peoList="peoList" :phone="phone" />

    <div class="gap"></div>

    <!-- 人员信息 -->
    <PaymentPeoList
      :canRefresh.sync="canRefreshChild"
      :order="order"
      :peoList="peoList"
      :confirmLoading="confirmLoading"
      :cancelLoading="cancelLoading"
      @refund="fetchData"
      @confirm="confirm"
      @cancel="cancel"
    />
  </van-pull-refresh>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentUI from "./components/PaymentUI";
import PaymentPeoList from "./components/PaymentPeoList";
import {
  flightOrderItemReload,
  applyIssueFlightOrder,
  cancelFlightOrder,
} from "@/api/aircraft";
import { fetchPhone } from "@/common/config.js";
export default {
  computed: {
    ...mapGetters(["orderItem", "nextOrderItem"]),
  },
  components: { PaymentUI, PaymentPeoList },
  data() {
    return {
      phone: "", // 服务商电话
      order: {}, // 订单
      peoList: [], // 人员
      confirmLoading: false, // 按钮转圈圈
      cancelLoading: false,
      isLoading: false, // 刷新
      canRefresh: true, // 可否刷新
      canRefreshChild: true, // 可否刷新
      next: false, // 是否需要转到返程订单
    };
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.fetchData(this.orderItem, false);
      }, 1000);
    },
    // ajax 获取数据
    fetchData(
      orderItem = { orderNo: null, outOrderNo: null, corp: null },
      f = true
    ) {
      let corp =
        orderItem.corp ||
        this.$route.query.corp ||
        this.order.corp ||
        this.orderItem.corp;
      this.phone = fetchPhone(corp); // 更新服务商电话
      if (f) this.canRefresh = f;
      if (this.canRefresh)
        flightOrderItemReload({
          orderNo:
            orderItem.orderNo ||
            this.$route.query.orderNo ||
            this.order.orderNo ||
            this.orderItem.orderNo,
          outOrderNo:
            orderItem.outOrderNo ||
            this.$route.query.outOrderNo ||
            this.order.outOrderNo ||
            this.orderItem.outOrderNo||'',
          corp: corp,
        }).then((response) => {
          this.order = response.order;
          let peos = response.orderItem;
          peos.forEach((element) => {
            let id = element.identityNo;
            let insuranceList = response.insuranceOrderItems || []; // 防空值
            let res = insuranceList.filter((e) => {
              return e.identityNo == id;
            });
            element.insurance = res;
          });
          this.peoList = peos;
          this.isLoading = false;
          this.canRefresh = false;
          setTimeout(() => {
            this.canRefresh = true;
          }, 30 * 1000);
        });
      else {
        this.$notify({
          type: "warning",
          message: "请勿频繁刷新，请30秒后再次尝试。",
        });
        this.isLoading = false;
      }
    },
    // 出票
    confirm() {
      this.confirmLoading = true;
      applyIssueFlightOrder({
        orderNo: this.order.orderSerialNo,
        outOrderNo: this.order.outOrderNo,
        originalOrderNo: this.order.bz3 || this.order.orderSerialNo,
        corp: this.order.corp,
      })
        .then((response) => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          this.fetchData();
          this.confirmLoading = false;
          if (this.next && !!this.nextOrderItem) {
            this.nextOrder();
          } else {
            this.$toast.success("申请出票成功，请稍后。");
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    // 取消出票
    cancel() {
      this.$dialog.confirm({
        title: '提示',
        message: '是否要取消订单？',
      })
        .then(() => {
          this.cancelLoading = true;
          cancelFlightOrder({
            orderNo: this.order.orderSerialNo,
            outOrderNo: this.order.outOrderNo,
            originalOrderNo: this.order.bz3 || this.order.orderSerialNo,
            corp: this.order.corp,
          })
            .then((response) => {
              this.fetchData();
              this.cancelLoading = false;
              if (this.next && !!this.nextOrderItem) {
                this.nextOrder("取消");
              } else {
                this.$toast.success("申请取消成功，请稍后。");
              }
            })
            .catch(() => {
              this.cancelLoading = false;
            });
        })
    },
    nextOrder(type = "出票") {
      this.$dialog
        .confirm({
          title: "订单" + type + "成功(去程)！",
          message: "是否立即查看返程订单？（也可以自行前往首页-订单中查看）",
        })
        .then(() => {
          this.next = false;
          this.fetchData({
            orderNo: this.nextOrderItem.orderSerialNo,
            outOrderNo: this.nextOrderItem.outOrderNo,
            originalOrderNo:
              this.nextOrderItem.bz3 || this.nextOrderItem.orderSerialNo,
            corp: this.nextOrderItem.corp,
          });
        })
        .catch(() => {});
    },
  },
  activated() {
    this.peoList = [];
    this.order = {};
    // this.isLoading = true;
    // 错误检测，没有订单号转到订单页
    if (
      !(
        Object.prototype.hasOwnProperty.call(this.$route.query, 'orderNo') ||
        !!this.orderItem.orderNo ||
        !!this.order.orderSerialNo
      )
    )
      this.$router.push("/plan");
    this.next = Object.prototype.hasOwnProperty.call(this.$route.query, 'cannext')
      ? this.$route.query.cannext
      : false;
    this.fetchData();
  },
};
</script>